import React, { useState , useEffect} from 'react';
import DateTH from '../../../Helper/date-th';
import printJS from 'print-js';
import LabelFormMuti from './LabelFormMuti';
import ModalsShopCode from './ModalsShopCode';
import ModalsStatus from './ModalsStatus';
import ModalsMG from './ModalsMG';
import ModalsHistory from './ModalsHistory';
import ModalsReceivedImg from './ModalsReceivedImg';
import ModalImage from './ModalImage';
import { nanoid } from 'nanoid';
import Pagination from '@material-ui/lab/Pagination';
import {
  BarcodeUpdateService,
  CODUpdateService,
  UpdateCustService,
  UploadImage,
  HistoryParcel,
  deliverImage,
  getIndividualConoteData,
  getMultipleConoteData,
  deliverFailImgService, deleteDeliveryImage, scanStatusCheck
} from '../../../Service/service.Barcode';
import swal from 'sweetalert';
import {ManageBarCodeExcelExport} from "./ManageBarCodeExcelExport";
import {ExportWithoutPictures} from "./ExportWithoutPictures";
import * as Icon from "react-bootstrap-icons";
import {ExportProgress} from "../../../Components/ExportProgress/ExportProgress";
import {ExportProgressStatus} from "../../../enums/ExportProgressStatus";
import {update_ref_no_status} from "../printLabel/conoteModal/conoteService";
import {swalError, swalSuccess} from "../../../Components/sweetAlert";
import PrintContent from "../printLabel/conoteModal/printContentsOLD";
import MultipleEditModal from './MultipleEditModal';
import EditRefNo from './EditRefNo';
import RemarkHistory from './RemarkHistory';
import {barcodeHasComputedErrors, getEmptyBarcodeParcels} from "./utils/MgBarcodes.utils";
import {MgBarcodeErrorList} from "./UI/MgBarcodeErrorList";
import {MgBarcodeUpload} from "./UI/MgBarcodeUpload";
import { useHistory } from "react-router-dom";
import { green, yellow } from '@material-ui/core/colors';

//TOUCH
function ShowData({
  data,
  setData,
  setPageSizes,
  pageSizes,
  values,
  fetchData,
  page,
  currentPage,
  setCurrentPage,
  sum,
  dataSelect,
  setDataSelect,
  printLabel,
  setPrintLabel,
  printLabelSingle,
  setPrintLabelSingle,
  fetchExportData,
  fetchExportData2,
  exportData,
  exportData2,
  clearExportData,
  isExporting,
}) {
  const [modalShopCode, setModalShopCode] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [modalMG, setModalMG] = useState(false);
  const [modalHis, setModalHis] = useState(false);
  const [modalReceived, setModalReceived] = useState(false);
  const [modalImg, setModalImg] = useState(false);
  const [notImg, setNotImg] = useState(true);
  const [historyData, setHistoryData] = useState([]);
  const [deliverData, setDeliverData] = useState([]);
  const [deliverDataId, setDeliverDataId] = useState(null);
  const [conoteData, setConoteData] = useState(null);
  const [failImgData, setFailImgData] = useState([]);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showUpdateRef_no, setShowUpdateRef_no] = useState(false);
  const [currentRemarkHistory, setCurrentRemarkHistory] = useState(null);
  const [barcodeErrorData, setBarcodeErrorData] = useState(null);
  const [barcodeReceivedData, setBarcodeReceivedData] = useState(null);
  const history = useHistory();
  const checked = () => {
    dataSelect.map((item, i) => {
      if (document.getElementById('flexCheckDefault' + item.code) != null) {
        document.getElementById('flexCheckDefault' + item.code).checked = true;
      }
    });
  };

  const initExportData = async () => {
    fetchExportData({
      search: values.search,
      shipment_status: values.shipment_status,
      start_date: values.startYear !== '' && values.startMonth !== '' && values.startDay !== '' ? values.startYear + '-' + values.startMonth + '-' + values.startDay : '',
      end_date: values.endYear !== '' && values.endMonth !== '' && values.endDay !== '' ? values.endYear + '-' + values.endMonth + '-' + values.endDay : '',
      cust_code: values.brand,
      cod_status: values.cod_status,
      show_cancel: localStorage.getItem("superkey")==="admin" || localStorage.getItem("superkey")==="service"?true:false,
    });
  };

  const initExportData2 = async () => {
    fetchExportData2({
      search: values.search,
      shipment_status: values.shipment_status,
      start_date: values.startYear !== '' && values.startMonth !== '' && values.startDay !== '' ? values.startYear + '-' + values.startMonth + '-' + values.startDay : '',
      end_date: values.endYear !== '' && values.endMonth !== '' && values.endDay !== '' ? values.endYear + '-' + values.endMonth + '-' + values.endDay : '',
      page: 1,
      number_list: pageSizes,
      cust_code: values.brand,
      cod_status: values.cod_status,
      show_cancel: localStorage.getItem("superkey")==="admin" || localStorage.getItem("superkey")==="service"?true:false,
    });
  };

  async function addPrints(value) {
    if (printLabel.length === 0 || printLabel.filter((a) => a.awb_code === value.awb_code).length === 0) {
        let data = [...printLabel, { ...value }];
        setPrintLabel(data);
    } else {
        setPrintLabel([...data.filter((a) => a.awb_code !== value.awb_code)]);
    }
  }

  async function deliveryFailImg(awb_code,ref_no){
    //setFailImgData([]);
    const res = await deliverFailImgService({
      awb_code: awb_code
    });
    if (res !== undefined) {
      if (res.length > 0) {
        //alert(JSON.stringify(res));
        setFailImgData(res);
      
      }
      else{
        const res2 = await deliverFailImgService({
          awb_code: ref_no
        }); 

        if (res2 !== undefined) {
          if (res2.length > 0) {
            //alert(JSON.stringify(res));
            setFailImgData(res2);
          
          }
          else{
            setFailImgData([]);
          }
        }
        else{
          setFailImgData([]);
        }
      }
    }
    else{
      setFailImgData([]);
    }
  }

  function dataArray(data) {
    let check = document.getElementById('flexCheckDefault' + data.code).checked;
    if (check) {
      setDataSelect((t) =>
        t.concat({
          code: data.code,
          awb_code: data.awb_code !== null ? data.awb_code : data.ref_no,
          cust_code: localStorage.getItem('username'),
          shipment_status: '',
          receiver_province: data.receiver_province,
          ref_no: data.ref_no,
          invoice: data.invoice,
          height: data.box_height,
          length: data.box_length,
          size: data.box_size,
          width: data.box_width,
          weight: data.weight,
          receiver_amphur: data.receiver_amphur,
          cod_cost: data.cod_cost
        }),
      );
    } else {
      let array = dataSelect;
      setDataSelect([]);
      array.map((row) => {
        if (row.code !== data.code) {
          setDataSelect((t) =>
            t.concat({
              code: row.code,
              awb_code: row.awb_code,
              cust_code: localStorage.getItem('username'),
              shipment_status: '',
            }),
          );
        }
      });
    }
  }
  function dataArrayAll(check) {
    setDataSelect([]);
    setPrintLabel([]);
    if (check) {
      data
        .filter((a) => a.awb_code !== '' && a.awb_code !== null && a.awb_code !== undefined)
        .map((item) => {
          if (document.getElementById('flexCheckDefault' + item.code) != null) {
            document.getElementById('flexCheckDefault' + item.code).checked = true;
          }
          setDataSelect((t) =>
            t.concat({
              code: item.code,
              awb_code: item.awb_code !== null ? item.awb_code : item.ref_no,
              cust_code: localStorage.getItem('username'),
              shipment_status: '',
            }),
          );
          setPrintLabel((t) => t.concat(item));
        });
    } else {
      data.map((item) => {
        if (document.getElementById('flexCheckDefault' + item.code) != null) {
          document.getElementById('flexCheckDefault' + item.code).checked = false;
        }
      });
    }
  }

  async function UpdateBarcode(value) {
    const res = await BarcodeUpdateService(value);
    if (res.status === 1) {
      swal({
        title: 'สำเร็จ!',
        text: '',
        icon: 'success',
        showConfirmButton: false,
        button: 'ปิด',
        timer: 1500,
      });
      setDataSelect([]);
      setPrintLabel([]);
      setData([]);
      fetchData({
        search: values.search,
        shipment_status: values.shipment_status,
        start_date: values.startYear !== '' && values.startMonth !== '' && values.startDay !== '' ? values.startYear + '-' + values.startMonth + '-' + values.startDay : '',
        end_date: values.endYear !== '' && values.endMonth !== '' && values.endDay !== '' ? values.endYear + '-' + values.endMonth + '-' + values.endDay : '',
        page: 1,
        number_list: pageSizes,
        cust_code: values.brand,
        cod_status: values.cod_status,
        show_cancel: localStorage.getItem("superkey")==="admin" || localStorage.getItem("superkey")==="service"?true:false,
        customer_type: values.customerType,
      });
    } else {
      swal({
        title: 'อัพเดทไม่สำเร็จ',
        text: '',
        icon: 'warning',
        showConfirmButton: false,
        button: 'ปิด',
        timer: 1500,
      });
    }
  }

  async function UpdateCOD(value) {
    const res = await CODUpdateService(value);
    if (res.status === 1) {
      swal({
        title: 'สำเร็จ!',
        text: '',
        icon: 'success',
        showConfirmButton: false,
        button: 'ปิด',
        timer: 1500,
      });
      setData([]);
      fetchData({
        search: values.search,
        shipment_status: values.shipment_status,
        start_date: values.startYear !== '' && values.startMonth !== '' && values.startDay !== '' ? values.startYear + '-' + values.startMonth + '-' + values.startDay : '',
        end_date: values.endYear !== '' && values.endMonth !== '' && values.endDay !== '' ? values.endYear + '-' + values.endMonth + '-' + values.endDay : '',
        page: 1,
        number_list: pageSizes,
        cust_code: values.brand,
        cod_status: values.cod_status,
        show_cancel: localStorage.getItem("superkey")==="admin" || localStorage.getItem("superkey")==="service"?true:false,
        customer_type: values.customerType,
      });
    } else {
      swal({
        title: 'อัพเดทไม่สำเร็จ',
        text: '',
        icon: 'warning',
        showConfirmButton: false,
        button: 'ปิด',
        timer: 1500,
      });
    }
  }

  async function UpdateCust(value) {
    const res = await UpdateCustService(value);
    if (res.status === 1) {
      swal({
        title: 'สำเร็จ!',
        text: '',
        icon: 'success',
        showConfirmButton: false,
        button: 'ปิด',
        timer: 1500,
      });
      setData([]);
      fetchData({
        search: values.search,
        shipment_status: values.shipment_status,
        start_date: values.startYear !== '' && values.startMonth !== '' && values.startDay !== '' ? values.startYear + '-' + values.startMonth + '-' + values.startDay : '',
        end_date: values.endYear !== '' && values.endMonth !== '' && values.endDay !== '' ? values.endYear + '-' + values.endMonth + '-' + values.endDay : '',
        page: 1,
        number_list: pageSizes,
        cust_code: values.brand,
        cod_status: values.cod_status,
        show_cancel: localStorage.getItem("superkey")==="admin" || localStorage.getItem("superkey")==="service"?true:false,
        customer_type: values.customerType,
      });
    } else {
      swal({
        title: 'อัพเดทไม่สำเร็จ',
        text: '',
        icon: 'warning',
        showConfirmButton: false,
        button: 'ปิด',
        timer: 1500,
      });
    }
  }

  async function Upload(value) {
    const res = await UploadImage(value);
    if (res.status) {
      swal({
        title: res.message,
        text: '',
        icon: 'success',
        showConfirmButton: false,
        button: 'ปิด',
        timer: 1500,
      });
      setData([]);
      fetchData({
        search: values.search,
        shipment_status: values.shipment_status,
        start_date: values.startYear !== '' && values.startMonth !== '' && values.startDay !== '' ? values.startYear + '-' + values.startMonth + '-' + values.startDay : '',
        end_date: values.endYear !== '' && values.endMonth !== '' && values.endDay !== '' ? values.endYear + '-' + values.endMonth + '-' + values.endDay : '',
        page: 1,
        number_list: pageSizes,
        cust_code: values.brand,
        cod_status: values.cod_status,
        show_cancel: localStorage.getItem("superkey")==="admin" || localStorage.getItem("superkey")==="service"?true:false,
        customer_type: values.customerType,
      });
    } else {
      swal({
        title: res.message,
        text: '',
        icon: 'warning',
        showConfirmButton: false,
        button: 'ปิด',
        timer: 1500,
      });
    }
  }

  async function History(value) {
    setHistoryData([]);
    const res = await HistoryParcel(value);
    if (res !== undefined) {
      if (res.length > 0) {
        setHistoryData(res);
      }
    }
  }

  async function Deliver(value) {
    setDeliverData([]);
    setDeliverDataId(value.id);
    const res = await deliverImage(value);
    if (res !== undefined) {
      if (res.length > 0) {
        setDeliverData(res);
      }
    }
  }

  function refresh(){
    setPrintLabelSingle(null);
    setData([]);
    fetchData({
      search: values.search,
      shipment_status: values.shipment_status,
      start_date: values.startYear !== '' && values.startMonth !== '' && values.startDay !== '' ? values.startYear + '-' + values.startMonth + '-' + values.startDay : '',
      end_date: values.endYear !== '' && values.endMonth !== '' && values.endDay !== '' ? values.endYear + '-' + values.endMonth + '-' + values.endDay : '',
      page: currentPage,
      number_list: pageSizes,
      cust_code: values.brand,
      cod_status: values.cod_status,
      show_cancel: localStorage.getItem("superkey")==="admin" || localStorage.getItem("superkey")==="service"?true:false,
      customer_type: values.customerType,
    });
  }
  useEffect(() => {
    if(conoteData && conoteData.length !== 0){
      printJS({
        printable: "printReportMultiple",
        type: "html",
        scanStyles: false,
        css: [
          window.location.protocol + "//" + window.location.host + "/static/Conote.css",
          "https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap",
        ],
        style: "@page { page-break-before: always;size: 20cm 14cm; margin:0 !important;border: none !important; padding: 0 !important; } ",
        onLoadingStart: () => {
          update_ref_no_status(conoteData.filter(row => row.conote_print_status == "0")
              .map(checked => checked.ref_no))
              .then(result => {
                setConoteData(null);
              }).catch(error => {
            swalError({
              text: "เกิดข้อผิดพลาดระหว่างการอัปเดตสถานะบันทึกร่วม"
            })
          })
        }
      })
    }
  }, [conoteData]);

  const scanToReceived = async () => {
      try{
        const result = await scanStatusCheck(dataSelect.map(row => row.awb_code), '5');
        if(barcodeHasComputedErrors(result.data.data)){
          setBarcodeErrorData(result.data.data);
          return;
        }
        setBarcodeReceivedData(result.data.data);
      }
      catch(error){
        if (error.response?.status === 451) {
          setBarcodeErrorData(error.response?.data?.data || null);
        } else {
          swalError({
            text: (error.response?.data?.message || error.message),
          })
        }
      }
  };

  const deleteImage = async ({parcelId,imageId,awb_code}) => {
    try {
      const result = await deleteDeliveryImage(parcelId,imageId);
      console.log({
        result,
      });
      swalSuccess({
        text: 'ลบภาพแล้ว',
      });
      Deliver({
        id: parcelId,
        awb_code
      });
      refresh();
    }
    catch(error){
      console.error(error);
      swal({
        title: 'อัพเดทไม่สำเร็จ',
        text: '',
        icon: 'warning',
        showConfirmButton: false,
        button: 'ปิด',
        timer: 1500,
      });
    }
  }
  return (
    <>
      {/* {exportData && <ManageBarCodeExcelExport
          onExportBeginning={(value) => setExportProgression({
            ...exportProgression,
            total: value,
          })}
          onExportEvolving={(progress) => {setExportProgression(progress)}}
          exportData={exportData}
          exportFinished={clearExportData} /> } */}
      {barcodeReceivedData && <MgBarcodeUpload onBarcodeErrors={(data) => {
        setBarcodeReceivedData(null);
        setBarcodeErrorData(data);
      }} selectData={dataSelect} onHide={() => setBarcodeReceivedData(null)} />}
      {barcodeErrorData && <MgBarcodeErrorList
          validationData={barcodeErrorData}
          onHide={() => setBarcodeErrorData(null)}
      />}
      {exportData2 && <ExportWithoutPictures exportData2={exportData2} />}
      {currentRemarkHistory && <RemarkHistory parcel_id={currentRemarkHistory} onClose={() => {
        setCurrentRemarkHistory(null);
      }}  />}
      {/** MODAL FORM CHANGE CUSTOMER */}
      <ModalsShopCode data={data} printLabelSingle={printLabelSingle} dataSelect={dataSelect} UpdateCust={UpdateCust} modalShopCode={modalShopCode} setModalShopCode={setModalShopCode} />
      {/** MODAL FORM CHANGE STATUS */}
      <ModalsStatus data={data} printLabelSingle={printLabelSingle} dataSelect={dataSelect} UpdateBarcode={UpdateBarcode} modalStatus={modalStatus} setModalStatus={setModalStatus} />
      {/** MODAL FORM MANGER BARCODE */}
      {printLabelSingle && <ModalsMG
        Upload={Upload}
        notImg={notImg}
        setNotImg={setNotImg}
        data={data}
        printLabelSingle={printLabelSingle}
        setPrintLabelSingle={setPrintLabelSingle}
        dataSelect={dataSelect}
        UpdateBarcode={UpdateBarcode}
        UpdateCOD={UpdateCOD}
        modalMG={modalMG}
        setModalMG={setModalMG}
        finishUpdate={refresh}
      />}
      <ModalsHistory modalHis={modalHis} setModalHis={setModalHis} historyData={historyData} />
      {deliverData && <ModalsReceivedImg onDelete={(image) => deleteImage({
        parcelId: deliverDataId,
        imageId: image.id,
        awb_code: image.awb_code
      })}
      modalReceived={modalReceived}
      setModalReceived={setModalReceived}
      deliverData={deliverData} />}
      <ModalImage modalImg={modalImg} setModalImg={setModalImg} failImgData={failImgData} setFailImgData={setFailImgData} />
      <LabelFormMuti printLabel={printLabel} />
      <div className="row text-center">
        <div className="col-sm-3" style={{ fontSize: '25px' }}>
          ยอดรวม : {page.total.toLocaleString('en')} ชิ้น
        </div>
        <div className="col-sm-3" style={{ fontSize: '25px' }}>
          ค่าขนส่งสุทธิ : {parseInt(sum.shipment_cost === null ? 0 : sum.shipment_cost).toLocaleString('en')} บาท
        </div>
        <div className="col-sm-3" style={{ fontSize: '25px' }}>
          COD : {parseInt(sum.cod_cost === null ? 0 : sum.cod_cost).toLocaleString('en')} บาท
        </div>
      </div>
      <br />
      <div className="d-flex justify-content-between mb-1">
        <div>
        {localStorage.getItem("superkey")==="admin" ? (
          <span className="mr-1">
            <button
              type="button"
              style={{ width: '150px' }}
              disabled={dataSelect.length === 0 ? true : false}
              className={dataSelect.length === 0 ? 'btn btn-secondary mb-1' : 'btn btn-primary mb-1'}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal2"
              onClick={(e) => {
                setModalShopCode(true);
              }}
            >
              เปลี่ยนรหัสลูกค้า
            </button>
          </span>
        ):""}
          <span className="mr-1">
            <button
              type="button"
              style={{ width: '200px' }}
              disabled={dataSelect.length === 0 ? true : false}
              className={dataSelect.length === 0 ? 'btn btn-secondary mb-1' : 'btn btn-primary mb-1'}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal2"
              onClick={(e) => {
                setModalStatus(true);
              }}
            >
              อัพเดทสถานะหลายรายการ
            </button>
          </span>
          <span className="mr-1">
            <button
              type="button"
              style={{ width: '150px' }}
              disabled={dataSelect.length === 0 ? true : false}
              className={dataSelect.length === 0 ? 'btn btn-secondary mb-1' : 'btn btn-warning mb-1'}
              onClick={() => {
                printJS({
                  printable: 'labelNormal',
                  type: 'html',
                  scanStyles: false,
                  css: [
                    window.location.protocol + '//' + window.location.host + '/static/PrinterCOD.css',
                    'https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap',
                  ],
                  style: '@page {size: 10cm 7cm;margin: 0cm;}',
                });
              }}
            >
              พิมพ์ลาเบลเป็นชุด
            </button>
            {/* labelNormal*/}
          </span>
          <span className="mr-1">
            <button
              type="button"
              style={{ width: '200px' }}
              disabled={dataSelect.length === 0 ? true : false}
              className={dataSelect.length === 0 ? 'btn btn-secondary mb-1' : 'btn btn-success mb-1'}
              onClick={() => {
                printJS({
                  printable: 'labelAddress',
                  type: 'html',
                  scanStyles: false,
                  targetStyles: ['*'],
                  css: [
                    window.location.protocol + '//' + window.location.host + '/static/Printer.css',
                    'https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap',
                  ],
                  style: '@page {size: 10cm 15cm;margin: 0.3cm;}',
                });
              }}
            >
              พิมพ์ลาเบล/ที่อยู่เป็นชุด
            </button>
            {/*labelAddress */}
          </span>
          <span className="mr-1">
            <button
                type="button"
                onClick={() => {
                  const ref_no_set = new Set(printLabel.map(row => row.ref_no));
                  getMultipleConoteData([...ref_no_set])
                      .then(result => {
                          console.log("result =", result);
                          const conoteData = result?.data?.data;
                          const groupData = result?.data?.groupData;
                          conoteData?.forEach((row) => {
                            row.groupData = groupData.filter(group => row.ref_no === group.ref_no);
                          });
                          if(!conoteData){
                            swalError({
                              title: 'เกิดข้อผิดพลาด',
                              text: 'ไม่สามารถเรียกข้อมูลบันทึกร่วมได้',
                            });
                            return;
                          }
                          console.log(conoteData);
                          setConoteData(conoteData);
                      }, error => {
                        console.log('error =', error);
                        swalError({
                            title: 'เกิดข้อผิดพลาด',
                            text: 'ไม่สามารถเรียกข้อมูลบันทึกร่วมได้',
                        });
                      });
                }}
                disabled={printLabel.length === 0}
                className={printLabel.length === 0 ? 'btn btn-secondary mb-1' : 'btn btn-primary mb-1'}>
              ปริ้นco note หลายใบ
            </button>
          </span>
          <span className="mr-1">
            <button
              type="button"
              onClick={() => setShowUpdate(true)}
              disabled={dataSelect.length === 0 ? true : false}
              className={dataSelect.length === 0 ? 'btn btn-secondary mb-1' : 'btn btn-success mb-1'}>
                {/* <Icon.Pen />  */}
                แก้ไขข้อมูลพัสดุฯ
            </button>
          </span>
          <span className="mr-1">
            <button
              type="button"
              onClick={() => setShowUpdateRef_no(true)}
              disabled={dataSelect.length === 0 ? true : false}
              className={dataSelect.length === 0 ? 'btn btn-secondary mb-1' : 'btn btn-success mb-1'}>
                {/* <Icon.Pen />  */}
                แก้ไขข้อมูลพัสดุฯหลายชิ้น
            </button>
          </span>
          {localStorage.getItem("superkey")==="admin" ? (
            <span className="mr-1">
              <button
                  type="button"
                  onClick={() => scanToReceived()}
                  disabled={dataSelect.length === 0 ? true : false}
                  className={dataSelect.length === 0 ? 'btn btn-secondary mb-1' : 'btn btn-primary mb-1'}>
                  {/* <Icon.Pen />  */}
                สแกนเพื่อรับ
              </button>
            </span>
          ):""}
          <span className="mr-1">
            <button
            type="button"
            // style={{ width: '100px' }}
            className="btn btn btn-info mb-1"
            onClick={initExportData2}>
              <Icon.FileEarmarkArrowUp /> export
            </button>
          </span>
          <span className="mr-1">
            <button
            type="button"
            // style={{ width: '100px' }}
            className="btn btn btn-info mb-1"
            onClick={initExportData}>
              <Icon.FileEarmarkArrowUp /> export/pic
            </button>
          </span>
        </div>
        <div className="col-sm-1">
          <select
            value={pageSizes}
            className="form-control mb-1"
            aria-label="Default select example"
            onChange={(e) => {
              setPageSizes(e.target.value);
              setDataSelect([]);
              setPrintLabel([]);
              setConoteData([]);
              setCurrentPage(1);
              fetchData({
                search: values.search,
                shipment_status: values.shipment_status,
                start_date: values.startYear !== '' && values.startMonth !== '' && values.startDay !== '' ? values.startYear + '-' + values.startMonth + '-' + values.startDay : '',
                end_date: values.endYear !== '' && values.endMonth !== '' && values.endDay !== '' ? values.endYear + '-' + values.endMonth + '-' + values.endDay : '',
                page: 1,
                number_list: e.target.value,
                cust_code: values.brand,
                cod_status: values.cod_status,
                show_cancel: localStorage.getItem("superkey")==="admin" || localStorage.getItem("superkey")==="service"?true:false,
                customer_type: values.customerType,
              });
            }}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>
      <div style={{ overflow: 'auto' }}>
        <table className="table table-bordered">
          <thead style={{ backgroundColor: '#3f75c1' }}>
            <tr style={{ color: '#FFFFFF' }}>
              <td style={{ width: '1%', textAlign: 'center' }}>
                <input
                  checked={
                    parseInt(dataSelect.length) !== 0 && parseInt(dataSelect.length) === parseInt(data.filter((a) => a.awb_code !== '' && a.awb_code !== null && a.awb_code !== undefined).length)
                      ? true
                      : false
                  }
                  style={{ width: '18px', height: '18px' }}
                  type="checkbox"
                  onChange={(e) => {
                    dataArrayAll(e.target.checked);
                  }}
                />
              </td>
              <td></td>
              <td style={{ width: '7%' }}>วันเปิดบิล</td>
              <td style={{ width: '7%' }}>รหัสลูกค้า</td>
              <td style={{ width: '8%' }}>บาร์โค้ด/สำหรับลูกค้า</td>
              <td style={{ width: '6%' }}>ผู้ส่ง</td>
              <td style={{ width: '8%' }}>ผู้รับ/ที่อยู่</td>
              {localStorage.getItem("superkey") ==="service" ?(
                <td>น้ำหนัก</td>
              ):""}
              {localStorage.getItem("superkey") ==="service" ?(
                <td>ขนาด(ซม)</td>
              ):""}
              {/* <td style={{ width: '3%' }}>จำนวน (ชิ้น)</td> */}
              <td style={{ width: '8%' }}>ค่าขนส่ง</td>
              <td style={{ width: '8%' }}>COD</td>
              <td style={{ width: '10%' }}>ยอดสุทธิ (บาท)</td>
              <td style={{ width: '11%' }}>หมายเหตุ</td>
              <td style={{ width: '13%' }}>สถานะ</td>
              <td style={{ width: '2%' }}></td>
            </tr>
          </thead>
          <tbody>
            {data.map((value, index) => (
              <tr key={index + 'Key' + (value.awb_code !== null ? value.awb_code : value.ref_no)}>
                <td>
                  <center>
                    <div className="form-check">
                      <input
                        disabled={value.awb_code === null ? true : false}
                        style={{ width: '18px', height: '18px' }}
                        onChange={(e) => {
                          dataArray(value);
                          addPrints(value);
                        }}
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        name={'code' + index}
                        id={'flexCheckDefault' + value.code}
                      />
                    </div>
                  </center>
                </td>
                <td>{(page.current_page - 1) * pageSizes + (index + 1)}</td>
                <td>
                  <DateTH date={value.created_date} />
                </td>
                <td>{value.cust_code}</td>
                <td>
                  <b
                    type="button"
                    onClick={() => {
                      setModalHis(true);
                      History({ awb_code: value.awb_code, ref_no: value.ref_no === null || value.ref_no === '0' ? " " : value.ref_no});
                    }}
                  >
                    {value.ref_no === null || value.ref_no === '0' ? " " : value.ref_no}
                    <br/>
                    {value.awb_code !== null ? value.awb_code : " "}
                  </b>
                  <hr/>
                  {value.ref_no2 === null || value.ref_no2 === "" ? " ": ( <p>เลขสำหรับลูกค้า 1: {value.ref_no2}</p>)}
                  {value.invoice === null || value.invoice === "" ? " ": ( <p>หมายเลขinvoice: {value.invoice}</p>)}
                  {value.item_desc === null || value.item_desc === "" ? " ": ( <p>เลขสำหรับลูกค้า 2: {value.item_desc}</p>)}
                  {value.product_code === null || value.product_code === "" ? " ": ( <p>Product code: {value.product_code}</p>)}
                  <font color="blue" size="4">
                    <p
                        type="button"
                        onClick={(e) => {
                          // const url = `/mis/UpdateHistory/${value.code}`;
                          window.open(`/mis/UpdateHistory/${value.code}`, '_blank');
                          // history.push(`/mis/UpdateHistory/${value.code}`)}
                        }}
                    >
                      แก้ไขข้อมูล
                    </p>
                                      </font>
                  
                </td>
                <td>{value.sender_name}
                <p>{value.sender_phone}</p>
                </td>
                <td>
                  <p>{value.receiver_name}</p>
                  <p>{value.receiver_address}</p>
                  <p>{value.receiver_district!== null ? value.receiver_district : ""} {' '+ value.receiver_amphur + ', ' + value.receiver_province}</p>
                  <p>{value.receiver_phone}</p>
                </td>
                {localStorage.getItem("superkey") ==="service" ?(
                  <td>{value.weight}</td>
              ):""}
              {localStorage.getItem("superkey") ==="service" ?(
                <td>{value.box_size} <br/> {value.parcel_size}</td>
              ):""}
                {/* <td>{value.num_parcel}</td> */}
                <td>{parseFloat(value.shipment_cost).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                <td>
                  {/* <p>ค่าขนส่ง: {parseFloat(value.shipment_cost).toLocaleString(undefined, { minimumFractionDigits: 2 })} </p> */}
                  <p>COD(บาท): {parseFloat(value.cod_cost).toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
                  <p>ค่าบริการ COD: {parseFloat(value.service_cod).toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
                </td>
                <td>{(parseFloat(value.shipment_cost) + parseFloat(value.service_cod)).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                <td>
                    {["admin","service"].includes(localStorage.getItem("superkey")) ? (
                      <span className="mr-1">
                      <b 
                      type="button"
                      onClick={(e) => {
                        setCurrentRemarkHistory(value.code);
                      }}>
                      {value.remarkMG !== null ? (
                            <p>{value.remarkMG}</p>) : (
                              <p>ไม่มีข้อสังเกต</p>
                            )}
                      </b>
                      </span>
                      ):""}
                        
                          </td>
                <td style={{ textAlign: 'center' }}>
                    {value.shipment_status !=='5' && value.shipment_status !=='15'?(
                      <b 
                      type="button"
                      onClick={(e) => {
                        setModalImg(true);
                        // alert(value.awb_code+' + '+value.ref_no)
                        deliveryFailImg(value.awb_code,value.ref_no);
                      }}>
                      {value.shipment_name}
                    </b>

                    ):(<div>
                      <b
                        type="button"
                        onClick={() => {
                          Deliver({
                            id: value.code,
                            awb_code: value.awb_code
                          });
                          setModalReceived(true);
                        }}
                      >
                        {value.shipment_name}
                      </b> 
                      {value.received_proof && value.received_proof > 0 
                      && value.received_proof != null && value.received_proof != '' ? 
                        (<font color="red" size="2">
                            <p>รูปหลักฐาน</p>
                          </font>) : ""}
                    </div>)
                    }
                    {value.shipment_status === '10' ? (
                    <center>
                        {value.new_receiver_date !== null && value.new_receiver_date !== '' && value.new_receiver_date !== undefined 
                        ? <DateTH date={value.new_receiver_date.substring(0, 10)} /> : ''}
                      
                    </center>
                    
                  ) : (
                    ''
                  )}
                  <br/>
                  {value.transportWorker !== "" && value.transportWorker !== null && value.scanner_name != ''
                  && value.scanner_name != null ? (
                    <p>รหัสพนักงานขนส่ง: "{value.transportWorker}"
                    <br/>ชื่อพนักงานขนส่ง: {value.scanner_name}
                    {/* <br/> วันที่/เวลาที่สแกน: <br/>
                    {value.shipment_datetime !== null && value.shipment_datetime !== '' 
                    && value.shipment_datetime !== undefined ? <DateTH date={value.shipment_datetime} /> : ''} */}
                    </p>
                  ):""}
                  <br/>
                  {value.shipment_status=== '3' && Date.parse(value.transit_due_date)<= Date.now() ?(
                    <div className='m-1' style={{backgroundColor: yellow}} > Late Transit </div>
                  ): (
                    <></>
                    // <div className='m-1' style={{backgroundColor: green}}> On Time Transit </div>
                  )

                  }
                </td>                
                <td align="center" style={{ width: '170px' }}>
                  {value.shipment_status === '5' || value.shipment_status =='15'  ? (
                    <center>
                      <img src={`${value.image}?${nanoid()}`} width="100" height="100" alt={value.image} />
                      <br />
                        {value.receiver_date !== null && value.receiver_date !== '' && value.receiver_date !== undefined ? <DateTH date={value.receiver_date} /> : ''}
                      
                    </center>
                    
                  ) : (
                    ''
                  )}
                  <br />
                  {localStorage.getItem("superkey") !== "staff" ?(
                      <div className="d-flex flex-column align-items-center justify-content-center" style={{gap: '5px'}}>
                        <button
                            type="button"
                            className="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            onClick={(e) => {
                              setModalMG(true);
                              setPrintLabelSingle(value);
                              setNotImg(true);
                            }}
                        >
                          จัดการ
                        </button>
                      </div>
                    ):""}
                    <br/>
                  <font color="red" size="4">
                    <p>{value.is_returned === '0'?'':'พัสดุถูกส่งกลับต้นทาง'}</p>
                  </font>
                  
                </td>
                {data.length === index + 1 ? checked() : ''}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between">
        <div>จำนวนทั้งหมด {parseInt(page.total).toLocaleString('en')} รายการ</div>
        <div>
          <Pagination
            count={parseInt(page.last_page)}
            page={currentPage}
            onChange={(e, newPage) => {
              setCurrentPage(newPage);
              fetchData({
                search: values.search,
                shipment_status: values.shipment_status,
                start_date: values.startYear !== '' && values.startMonth !== '' && values.startDay !== '' ? values.startYear + '-' + values.startMonth + '-' + values.startDay : '',
                end_date: values.endYear !== '' && values.endMonth !== '' && values.endDay !== '' ? values.endYear + '-' + values.endMonth + '-' + values.endDay : '',
                page: newPage,
                number_list: pageSizes,
                cust_code: values.brand,
                cod_status: values.cod_status,
                show_cancel: localStorage.getItem("superkey")==="admin" || localStorage.getItem("superkey")==="service"?true:false,
                customer_type: values.customerType,
              });
            }}
            color="primary"
            size="small"
            defaultPage={6}
            siblingCount={1}
          />
        </div>
      </div>
      {conoteData && conoteData.length !== 0 &&
      <div
          style={{display: "none"}}
      >
        <div id="printReportMultiple" style={{width: '20cm',margin: 0, padding: 0}}>
          {conoteData && conoteData.map((checked) => (
              <PrintContent
                  additionalStyles={{width: '20cm', height: '13.5cm',pageBreakAfter: 'always'}}
                  data={checked}
                  conoteGroups={checked.groupData}
              />
          ))}
        </div>
      </div>
      }
      {showUpdate && dataSelect && <MultipleEditModal barcodes={dataSelect} onFinish={() => {
          setShowUpdate(false);
          setDataSelect([]);
          refresh();
        }
      }
        onClose={() => setShowUpdate(false)}
      />} 
      {showUpdateRef_no && dataSelect && <EditRefNo barcodes={dataSelect} onFinish={() => {
          setShowUpdateRef_no(false);
          setDataSelect([]);
          refresh();
        }
      }
        onClose={() => setShowUpdateRef_no(false)}
      />}
    </>
  );
}

export default ShowData;
