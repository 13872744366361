import React, { useState } from "react";
import * as Icon from "react-bootstrap-icons";
import Pagination from "@material-ui/lab/Pagination";
import DateTH from "../../../Helper/date-th";
import { BarcodeService, BarcodeUpdateService, CODUpdateService, CustomerTypeService } from "../../../Service/service.Barcode";
import { CODService } from "../../../Service/service.CashOnDelivery";
import { cod_status_list } from "../../../Data/cod_status";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { monthTH } from "../../../Data/month-th.json";
import { nanoid } from "nanoid";
import { URLTEST, URL} from "../../../Helper/baseURL";
import ReactExport from "react-data-export";
import printJS from 'print-js';
import { connect } from "react-redux";
import { bank } from "../../../Data/bank";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const userInfo = localStorage.getItem("userInfo");

export default function HistoryCOD() {
  const history = useHistory();
  var year = new Date().getFullYear();
  var years = Array.from(new Array(3), (e, index) => year - index);
  var month = new Date().getMonth() + 1;
  var date = new Date().getDate();
  var [data2, Data2] = useState([]);
  const [setData, SetData] = useState([]);
  const [setData2, SetData2] = useState([]);
  const [setPage, SetPage] = useState({
    current_page: 0,
    last_page: 1,
    total: 0,
  });

  const [currentPage, SetcurrentPage] = useState(1);
  const [pageSizes, PageSizes] = useState(10);
  const [customer_type, Customer_type] = useState("");
  const [cust_code, Cust_code] = useState([]);
  const [cust, Cust] = useState([]);
  const [barcode, Barcode] = useState("");
  const [shipStatus, SetStatus] = useState("10");
  const [startdate1, SetStart1] = useState("");
  const [startdate2, SetStart2] = useState("");
  const [startdate3, SetStart3] = useState("");
  const [enddate1, SetEnd1] = useState("");
  const [enddate2, SetEnd2] = useState("");
  const [enddate3, SetEnd3] = useState("");
  const [cod, COD] = useState("");
  const [awbCodeSta, setCodStatus] = useState("");
  const [barcodeNumber, BarcodeNumber] = useState("");
  const [shipmentStatus, ShipmentStatus] = useState("");
  const [cod_cost, Cod_cost] = useState("");
  const [search_ID, Search_ID] = useState("");
  const [search_name, Search_name] = useState("");
  const [sender_name, Sender_name] = useState("");
  const [parcel_code, Parcel_Code] = useState("");
  const [bank_id, Bank_id] = useState("");
  const [bookbank_code, Bookbank_code] = useState("");
  const [bookbank_name, Bookbank_name] = useState("");
  const [setError, SetError] = useState("form-control");
  const [setErrorMass, SetErrorMass] = useState(0);
  const [setErrorMassNoData, SetErrorMassNoData] = useState(0);
  const [errorStartdate, SetErrorStart] = useState("form-control ");
  const [errorEnddate, SetErrorEnd] = useState("form-control ");
  const [errorStartMass, SetErrorStartMass] = useState(0);
  const [errorEndMass, SetErrorEndMass] = useState(0);
  const [errorMassSta, ErrorMassSta] = useState(0);
  const [loading, Loading] = useState(0);
  const [dateNow, setDateNow] = useState(1);
  const [printLebel, SetPrintLebel] = useState([]);
  const [sender_phone,Sender_phone] = useState("");
  const [national_id,National_id] = useState("");

  const [exportData, SetExportData] = useState([]);

  async function fetchData(value) {
    Loading(1);
    const result = await CODService(value);
    if (result != undefined) {
      if (result.data.length !== 0) {
        SetData(result.data);
        SetPage({
          current_page: result.pg[0].current_page??0,
          last_page: result.pg[0].last_page??0,
          total: result.pg[0].total??0,
        });
      }
      if (result.data.length !== 0) {
        SetError("form-control");
        SetErrorMass(0);
        SetErrorMassNoData(0);
      }
      if (result.data.length === 0) {
        SetcurrentPage(1);
        SetPage({
          current_page: 1,
          last_page: 1,
          total: 0,
        });
        SetErrorMassNoData(1);
      }
      if (result != null) {
        SetData(result.data);
      }
    } else {
      alert("Server Error");
    }
    Loading(0);
  }

  async function fetchData2(value) {
    Loading(1);
    const result = await CODService(value);
    if (result != undefined) {
      if (result.data.length !== 0) {
        SetData2(result.data);
        document.getElementById("downloadEx1").click();

        //document.getElementById("downloadEx1").click();
        // SetPage({
        //   current_page: result.pg[0].current_page,
        //   last_page: result.pg[0].last_page,
        //   total: result.pg[0].total,
        // });
      }
      if (result.data.length !== 0) {
        SetError("form-control");
        SetErrorMass(0);
        SetErrorMassNoData(0);
      }
      if (result.data.length === 0) {
        SetcurrentPage(1);
        // SetPage({
        //   current_page: 1,
        //   last_page: 1,
        //   total: 0,
        // });
        SetErrorMassNoData(1);
      }
      if (result != null) {
        SetData2(result.data);

      }
    } else {
      alert("Server Error");
    }
    Loading(0);
  }

  async function CustomerType(data) {
    const result = await CustomerTypeService(data);
    if (data != null) {
      Cust(result.data);
    }
  }

  async function UpdateBarcode(value) {
    const resultUpdate = await BarcodeUpdateService(value);
    if (resultUpdate.res[0].status == 1) {
      swal({
        title: "สำเร็จ!",
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
      SetData([]);
      fetchData({
        search: barcode != "" ? barcode : "",
        shipment_status: shipStatus != "" ? shipStatus : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
        page: 1,
        number_list: pageSizes,
        cust_code: cust_code != "" ? cust_code[0].cust_code : "",
        cod_status: cod != "" ? cod : "",
        search_name: search_name != "" ? search_name : "",
        national_id: national_id ? national_id : "",
        sender_phone: sender_phone != "" ? sender_phone : ""
      });
      Data2([]);
      ShipmentStatus("");
    } else {
      swal({
        title: "อัพเดทไม่สำเร็จ",
        text: "",
        icon: "warning",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
    }
  }

  async function UpdateCOD(value) {
    const resultUpdate = await CODUpdateService(value);
    if (resultUpdate.res[0].status == 1) {
      swal({
        title: "สำเร็จ!",
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
      SetData([]);
      fetchData({
        search: barcode != "" ? barcode : "",
        shipment_status: shipStatus != "" ? shipStatus : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
        page: 1,
        number_list: pageSizes,
        cust_code: cust_code != "" ? cust_code[0].cust_code : "",
        cod_status: cod != "" ? cod : "",
        search_name: search_name != "" ? search_name : "",
        national_id: national_id ? national_id : "",
        sender_phone: sender_phone != "" ? sender_phone : ""
      });
    } else {
      swal({
        title: "อัพเดทไม่สำเร็จ",
        text: "",
        icon: "warning",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
    }
  }

  function clear() {
    setDateNow(1);
    ErrorMassSta(0);
    SetErrorStartMass(0);
    SetErrorEndMass(0);
    SetErrorEnd("form-control");
    SetErrorStart("form-control");
    SetErrorMassNoData(0);
    SetStart1("");
    SetStart2("");
    SetStart3("");
    SetEnd1("");
    SetEnd2("");
    SetEnd3("");
    SetError("form-control");
    SetErrorMass(0);
    SetData([]);
    Data2([]);
    Cust_code([]);
    Cust([]);
    SetPrintLebel([]);
    Customer_type("");
    COD("");
    Barcode("");
    SetStatus("");
    SetcurrentPage(1);
    PageSizes(10);
    SetPage({
      current_page: 1,
      last_page: 1,
      total: 0,
    });
  }

  /** ค้นหาข้อมูล */
  const handleSubmit = () => {
    SetPrintLebel([]);
    Data2([]);
    SetcurrentPage(1);
    SetData([]);
    SetError("form-control");
    SetErrorMass(0);

    //ค้นหาเมื่อ startDate ไม่เท่ากับ "" และ EndDate ไม่เท่ากับ ""
    if (startdate1 != "" && startdate2 != "" && startdate3 != "" && enddate1 != "" && enddate2 != "" && enddate3 != "") {
      SetError("form-control");
      SetErrorMass(0);
      SetErrorStart("form-control");
      SetErrorMassNoData(0);
      SetErrorEnd("form-control");
      SetErrorMassNoData(0);
      SetErrorEndMass(0);
      SetErrorStartMass(0);
      fetchData({
        search: barcode != "" ? barcode : "",
        shipment_status: shipStatus != "" ? shipStatus : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
        page: "1",
        number_list: pageSizes,
        cust_code: cust_code != "" ? cust_code[0].cust_code : "",
        cod_status: cod != "" ? cod : "",
        search_name: search_name != "" ? search_name : "",
        national_id: national_id ? national_id : "",
        sender_phone: sender_phone != "" ? sender_phone : ""
      });
    }

    //ค้นหาเมื่อ startDate ไม่เท่ากับ "" และ EndDate เท่ากับ ""
    else if (
      startdate1 != "" &&
      startdate2 != "" &&
      startdate3 != "" &&
      enddate1 == "" &&
      enddate2 == "" &&
      enddate3 == ""
    ) {
      SetError("form-control");
      SetErrorMass(0);
      SetErrorStart("form-control");
      SetErrorMassNoData(0);
      SetErrorStartMass(0);
      SetErrorEnd("form-control");
      SetErrorEndMass(0);
      fetchData({
        search: barcode != "" ? barcode : "",
        shipment_status: shipStatus != "" ? shipStatus : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
        page: "1",
        number_list: pageSizes,
        cust_code: cust_code != "" ? cust_code[0].cust_code : "",
        cod_status: cod != "" ? cod : "",
        search_name: search_name != "" ? search_name : "",
        national_id: national_id ? national_id : "",
        sender_phone: sender_phone != "" ? sender_phone : ""
      });
    }

    //ค้นหาเมื่อ EndDate ไม่เท่ากับ "" และ startDate เท่ากับ ""
    else if (
      enddate1 != "" &&
      enddate2 != "" &&
      enddate3 != "" &&
      startdate1 == "" &&
      startdate2 == "" &&
      startdate3 == ""
    ) {
      SetError("form-control");
      SetErrorMass(0);
      SetErrorEnd("form-control");
      SetErrorMassNoData(0);
      SetErrorEndMass(0);
      SetErrorStart("form-control");
      SetErrorStartMass(0);
      fetchData({
        search: barcode != "" ? barcode : "",
        shipment_status: shipStatus != "" ? shipStatus : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
        page: "1",
        number_list: pageSizes,
        cust_code: cust_code != "" ? cust_code[0].cust_code : "",
        cod_status: cod != "" ? cod : "",
        search_name: search_name != "" ? search_name : "",
        national_id: national_id ? national_id : "",
        sender_phone: sender_phone != "" ? sender_phone : ""
      });
    }

    //แสดง validation เมื่อ startDate ตัวไดตัวหนึ่ง ไม่เท่ากับ "" และ EndDate ตัวไดตัวหนึ่ง ไม่เท่ากับ ""
    else if (
      (startdate1 != "" || startdate2 != "" || startdate3 != "") &&
      (enddate1 != "" || enddate2 != "" || enddate3 != "")
    ) {
      SetData([]);
      if (startdate1 == "" || startdate2 == "" || startdate3 == "") {
        SetErrorStart("dropdown-custom form-control is-invalid");
        SetErrorStartMass(1);
      }
      if (enddate1 == "" || enddate2 == "" || enddate3 == "") {
        SetErrorEnd("dropdown-custom form-control is-invalid");
        SetErrorEndMass(1);
      }
    }

    //แสดง validation เมื่อ startDate ตัวไดตัวหนึ่ง ไม่เท่ากับ ""  และ EndDate เท่ากับ ""
    else if (
      (startdate1 != "" || startdate2 != "" || startdate3 != "") &&
      enddate1 == "" &&
      enddate2 == "" &&
      enddate3 == ""
    ) {
      SetData([]);
      SetErrorStart("dropdown-custom form-control is-invalid");
      SetErrorStartMass(1);
      SetErrorEnd("form-control");
      SetErrorEndMass(0);
    }

    //แสดง validation เมื่อ EndDate ตัวไดตัวหนึ่ง ไม่เท่ากับ ""  และ startDate เท่ากับ ""
    else if (
      (enddate1 != "" || enddate2 != "" || enddate3 != "") &&
      startdate1 == "" &&
      startdate2 == "" &&
      startdate3 == ""
    ) {
      SetData([]);
      SetErrorEnd("dropdown-custom form-control is-invalid");
      SetErrorEndMass(1);
      SetErrorStart("form-control");
      SetErrorStartMass(0);
    } else {
      SetErrorStart("form-control");
      SetErrorStartMass(0);
      SetErrorEnd("form-control");
      SetErrorEndMass(0);
      fetchData({
        search: barcode != "" ? barcode : "",
        shipment_status: shipStatus != "" ? shipStatus : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
        page: "1",
        number_list: pageSizes,
        cust_code: cust_code != "" ? cust_code[0].cust_code : "",
        cod_status: cod != "" ? cod : "",
        search_name: search_name != "" ? search_name : "",
        national_id: national_id ? national_id : "",
        sender_phone: sender_phone != "" ? sender_phone : ""
      });
    }
  };

  async function handleSubmit2Excel(){
    SetPrintLebel([]);
    Data2([]);
    SetcurrentPage(1);
    SetData2([]);
    SetError("form-control");
    SetErrorMass(0);

    //ค้นหาเมื่อ startDate ไม่เท่ากับ "" และ EndDate ไม่เท่ากับ ""
    if (startdate1 != "" && startdate2 != "" && startdate3 != "" && enddate1 != "" && enddate2 != "" && enddate3 != "") {
      SetError("form-control");
      SetErrorMass(0);
      SetErrorStart("form-control");
      SetErrorMassNoData(0);
      SetErrorEnd("form-control");
      SetErrorMassNoData(0);
      SetErrorEndMass(0);
      SetErrorStartMass(0);
      fetchData2({
        search: barcode != "" ? barcode : "",
        shipment_status: shipStatus != "" ? shipStatus : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
        page: "1",
        number_list: "",
        cust_code: cust_code != "" ? cust_code[0].cust_code : "",
        cod_status: cod != "" ? cod : "",
        search_name: search_name != "" ? search_name : "",
        national_id: national_id ? national_id : "",
        sender_phone: sender_phone != "" ? sender_phone : ""
      });
    }

    //ค้นหาเมื่อ startDate ไม่เท่ากับ "" และ EndDate เท่ากับ ""
    else if (
      startdate1 != "" &&
      startdate2 != "" &&
      startdate3 != "" &&
      enddate1 == "" &&
      enddate2 == "" &&
      enddate3 == ""
    ) {
      SetError("form-control");
      SetErrorMass(0);
      SetErrorStart("form-control");
      SetErrorMassNoData(0);
      SetErrorStartMass(0);
      SetErrorEnd("form-control");
      SetErrorEndMass(0);
      fetchData2({
        search: barcode != "" ? barcode : "",
        shipment_status: shipStatus != "" ? shipStatus : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
        page: "1",
        number_list: "",
        cust_code: cust_code != "" ? cust_code[0].cust_code : "",
        cod_status: cod != "" ? cod : "",
        search_name: search_name != "" ? search_name : "",
        national_id: national_id ? national_id : "",
        sender_phone: sender_phone != "" ? sender_phone : ""
      });
    }

    //ค้นหาเมื่อ EndDate ไม่เท่ากับ "" และ startDate เท่ากับ ""
    else if (
      enddate1 != "" &&
      enddate2 != "" &&
      enddate3 != "" &&
      startdate1 == "" &&
      startdate2 == "" &&
      startdate3 == ""
    ) {
      SetError("form-control");
      SetErrorMass(0);
      SetErrorEnd("form-control");
      SetErrorMassNoData(0);
      SetErrorEndMass(0);
      SetErrorStart("form-control");
      SetErrorStartMass(0);
      fetchData2({
        search: barcode != "" ? barcode : "",
        shipment_status: shipStatus != "" ? shipStatus : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
        page: "1",
        number_list: "",
        cust_code: cust_code != "" ? cust_code[0].cust_code : "",
        cod_status: cod != "" ? cod : "",
        search_name: search_name != "" ? search_name : "",
        national_id: national_id ? national_id : "",
        sender_phone: sender_phone != "" ? sender_phone : ""
      });
    }

    //แสดง validation เมื่อ startDate ตัวไดตัวหนึ่ง ไม่เท่ากับ "" และ EndDate ตัวไดตัวหนึ่ง ไม่เท่ากับ ""
    else if (
      (startdate1 != "" || startdate2 != "" || startdate3 != "") &&
      (enddate1 != "" || enddate2 != "" || enddate3 != "")
    ) {
      SetData2([]);
      if (startdate1 == "" || startdate2 == "" || startdate3 == "") {
        SetErrorStart("dropdown-custom form-control is-invalid");
        SetErrorStartMass(1);
      }
      if (enddate1 == "" || enddate2 == "" || enddate3 == "") {
        SetErrorEnd("dropdown-custom form-control is-invalid");
        SetErrorEndMass(1);
      }
    }

    //แสดง validation เมื่อ startDate ตัวไดตัวหนึ่ง ไม่เท่ากับ ""  และ EndDate เท่ากับ ""
    else if (
      (startdate1 != "" || startdate2 != "" || startdate3 != "") &&
      enddate1 == "" &&
      enddate2 == "" &&
      enddate3 == ""
    ) {
      SetData2([]);
      SetErrorStart("dropdown-custom form-control is-invalid");
      SetErrorStartMass(1);
      SetErrorEnd("form-control");
      SetErrorEndMass(0);
    }

    //แสดง validation เมื่อ EndDate ตัวไดตัวหนึ่ง ไม่เท่ากับ ""  และ startDate เท่ากับ ""
    else if (
      (enddate1 != "" || enddate2 != "" || enddate3 != "") &&
      startdate1 == "" &&
      startdate2 == "" &&
      startdate3 == ""
    ) {
      SetData2([]);
      SetErrorEnd("dropdown-custom form-control is-invalid");
      SetErrorEndMass(1);
      SetErrorStart("form-control");
      SetErrorStartMass(0);
    } else {
      SetErrorStart("form-control");
      SetErrorStartMass(0);
      SetErrorEnd("form-control");
      SetErrorEndMass(0);
      fetchData2({
        search: barcode != "" ? barcode : "",
        shipment_status: shipStatus != "" ? shipStatus : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
        page: "1",
        number_list: "",
        cust_code: cust_code != "" ? cust_code[0].cust_code : "",
        cod_status: cod != "" ? cod : "",
        search_name: search_name != "" ? search_name : "",
        national_id: national_id ? national_id : "",
        sender_phone: sender_phone != "" ? sender_phone : ""
      });
    }
  };


  const SubmitUpdateStatus = () => {
    if (shipmentStatus != "") {
      var data = [
        {
          awb_code: barcodeNumber,
          shipment_status: shipmentStatus,
        },
      ];
      UpdateBarcode(data);
    }
  };

  const handleChangePage = (event, newPage) => {
    SetData([]);
    SetcurrentPage(newPage);
    fetchData({
      search: barcode != "" ? barcode : "",
      shipment_status: shipStatus != "" ? shipStatus : "",
      start_date:
        startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
      end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
      page: newPage,
      number_list: pageSizes,
      cust_code: cust_code != "" ? cust_code[0].cust_code : "",
      cod_status: cod != "" ? cod : "",
      search_name: search_name != "" ? search_name : "",
      national_id: national_id ? national_id : "",
      sender_phone: sender_phone != "" ? sender_phone : ""
    });
  };

  const checked = () => {
    data2.map((item, i) => {
      if (document.getElementById("flexCheckDefault" + item.awb_code) != null) {
        document.getElementById("flexCheckDefault" + item.awb_code).checked = true;
      }
    });
  };

  function pageSize(data) {
    SetData([]);
    SetcurrentPage(1);
    PageSizes(data);
    fetchData({
      search: barcode != "" ? barcode : "",
      shipment_status: shipStatus != "" ? shipStatus : "",
      start_date:
        startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
      end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
      page: "1",
      number_list: data,
      cust_code: cust_code != "" ? cust_code[0].cust_code : "",
      cod_status: cod != "" ? cod : "",
      search_name: search_name != "" ? search_name : "",
      national_id: national_id ? national_id : "",
      sender_phone: sender_phone != "" ? sender_phone : ""
    });
  }

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [showMenager, setShowMenager] = useState(false);
  const handleCloseMenager = () => setShowMenager(false);
  const handleShowMenager = () => setShowMenager(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  function dataArray(data) {
    let check = document.getElementById("flexCheckDefault" + data).checked;
    if (check) {
      Data2((t) =>
        t.concat({
          awb_code: data,
          shipment_status: shipmentStatus,
        })
      );
    } else {
      let array = data2;
      Data2([]);
      array.map((row, index) => {
        if (row.awb_code !== data) {
          Data2((t) =>
            t.concat({
              awb_code: row.awb_code,
              shipment_status: shipmentStatus,
            })
          );
        }
      });
    }
  }

  const SubmitUpdateStatusMuti = () => {
    if (shipmentStatus != "" && data2.length != 0) {
      var i;
      for (i = 0; i < data2.length; i++) {
        data2[i].shipment_status = shipmentStatus;
      }
      UpdateBarcode(data2);
    }
  };

  function addPrints(value) {
    if (printLebel.length === 0 || printLebel.filter((a) => a.awb_code === value.awb_code).length === 0) {
      let data = [...printLebel, { ...value }];
      SetPrintLebel(data);
    } else {
      let data = [...printLebel];
      SetPrintLebel([...data.filter((a) => a.awb_code !== value.awb_code)]);
    }
  }

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="container-fluid mt-2 mb-2">
            <div className="text-center text-title-color">
              <h2>ประวัติCOD</h2>
            </div>
            <br />
            <form
              onSubmit={(e) => {
                e.preventDefault();
                SetData([]);
                handleSubmit();
              }}
            >
              <div className="row justify-content-md-center">
                <div className="col-sm-4 col-md-4 px-md-1 mt-1">
                  <div className="input-group flex-nowrap">
                    <select
                      value={customer_type}
                      onChange={(e) => {
                        Customer_type(e.target.value);
                        CustomerType({ customer_type: e.target.value });
                      }}
                      className={"dropdown-custom2" + " " + setError}
                    >
                      {" "}
                      <option value={""}>{"ประเภทลูกค้า"}</option>
                      <option value={"4"}>{"เครดิต"}</option>
                      <option value={"3"}>{"หน้าร้าน"}</option>
                    </select>
                  </div>
                  {setErrorMass == 1 ? (
                    <font color="red" size="2">
                      {" "}
                      กรุณากรอกข้อมูล{" "}
                    </font>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-sm-4 col-md-4 px-md-1 mt-1">
                  <div className="input-group flex-nowrap">
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey={(option) => `${option.cust_code} ${option.name}`}
                      onChange={(e, v) => {
                        Cust_code(e);
                      }}
                      options={cust}
                      getOptionLabel={(item) => item.cust_code}
                      placeholder="ชื่อ"
                      selected={cust_code}
                      value={cust_code}
                    />
                  </div>
                  {setErrorMass == 1 ? (
                    <font color="red" size="2">
                      {" "}
                      กรุณากรอกข้อมูล{" "}
                    </font>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-sm-4 col-md-3 px-md-1 mt-1">
                  <div className="input-group flex-nowrap">
                    <input
                      type="text"
                      value={barcode}
                      onChange={(e) => Barcode(e.target.value)}
                      id="clear"
                      placeholder="ค้นหาบาร์โค้ด"
                      className={setError}
                    />
                  </div>
                  {setErrorMass == 1 ? (
                    <font color="red" size="2">
                      {" "}
                      กรุณากรอกข้อมูล{" "}
                    </font>
                  ) : (
                    ""
                  )}
                </div>
                {/* <div className="col-sm-3 col-md-3 px-md-1 mt-1">
                  <div className="input-group flex-nowrap">
                    <select
                      value={shipStatus}
                      onChange={(e) => {
                        SetStatus(e.target.value);
                      }}
                      className={"dropdown-custom2" + " " + setError}
                      placeholder="-- กรุณาเลือกสถานะ --"
                    >
                      <option style={{ color: "#000000" }} value={""}>
                        {"ทั้งหมด"}
                      </option>
                      {cod_status_list.map((item) => (
                        <option value={item.idcode} key={nanoid()}>
                          {item.id  + "." + " " + item.StatusName}
                        </option>
                      ))}
                    </select>
                  </div>
                  {setErrorMass == 1 ? (
                    <font color="red" size="2">
                      {" "}
                      กรุณากรอกข้อมูล{" "}
                    </font>
                  ) : (
                    ""
                  )}
                </div> */}
              </div>
              <br />
              <div className="row justify-content-md-center">
              <div className="col-sm-6 col-md-6 px-md-1 mt-1">
                  <div className="input-group flex-nowrap">
                    <input
                      type="text"
                      value={national_id}
                      onChange={(e) => National_id(e.target.value)}
                      id="clear"
                      placeholder="หาจากเลขบัตรประชาชน"
                      className={setError}
                    />
                  </div>
                  {setErrorMass == 1 ? (
                    <font color="red" size="2">
                      {" "}
                      กรุณากรอกข้อมูล{" "}
                    </font>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-sm-6 col-md-6 px-md-1 mt-1">
                  <div className="input-group flex-nowrap">
                    <input
                      type="text"
                      value={sender_phone}
                      onChange={(e) => Sender_phone(e.target.value)}
                      id="clear"
                      placeholder="หาจากเบอร์โทรศัพท์"
                    //   SEARCH BY PHONE NUM
                      className={setError}
                    />
                  </div>
                  {setErrorMass == 1 ? (
                    <font color="red" size="2">
                      {" "}
                      กรุณากรอกข้อมูล{" "}
                    </font>
                  ) : (
                    ""
                  )}
                </div>
              </div>
                <br />
              <div className="row justify-content-md-center">
                <div className="col-12 col-lg-2">
                  <br />
                  <center style={{ marginTop: "10px" }}>
                    <div className="form-check">
                      <input
                        checked={dateNow === 1 ? false : true}
                        style={{ width: "15px", height: "15px" }}
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        onChange={(e) => {
                          if (dateNow === 1) {
                            setDateNow(0);
                            SetStart1(year);
                            SetStart2(("0" + month).slice(-2));
                            SetStart3(date);
                            SetEnd1(year);
                            SetEnd2(("0" + month).slice(-2));
                            SetEnd3(date);
                          } else {
                            setDateNow(1);
                            SetStart1("");
                            SetStart2("");
                            SetStart3("");
                            SetEnd1("");
                            SetEnd2("");
                            SetEnd3("");
                          }
                        }}
                      />
                      <label className="form-check-label" htmlFor="flexCheckDefault" style={{ marginLeft: "10px" }}>
                        วันที่ปัจจุบัน
                      </label>
                    </div>
                  </center>
                </div>
                <div className="col-12 col-lg-4">
                  <span>ตั้งแต่วันที่เปิดบิล</span>
                  <div className="row">
                    <div className="col-12 col-sm-4 col-md-4 px-md-1 mt-1">
                      <select
                        className={errorStartdate}
                        value={startdate1}
                        onChange={(e) => {
                          SetStart1(e.target.value);
                        }}
                      >
                        <option value=""> -- ปี -- </option>
                        {years.map((year, index) => (
                          <option key={`year${index}`} value={year}>
                            {year + 543}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 col-sm-4 col-md-4  px-md-1 mt-1">
                      <select
                        className={errorStartdate}
                        value={startdate2}
                        onChange={(e) => {
                          SetStart2(e.target.value);
                        }}
                      >
                        <option value=""> -- เดือน -- </option>
                        {monthTH.map((item, index) => (
                          <option value={item.id} key={item.id}>
                            {item.monthName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 col-sm-4 col-md-4  px-md-1 mt-1">
                      <select
                        className={errorStartdate}
                        value={startdate3}
                        onChange={(e) => {
                          SetStart3(e.target.value);
                        }}
                      >
                        <option value=""> -- วัน -- </option>
                        {Array.from(Array(31), (e, i) => (
                          <option value={i + 1}>{i + 1}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {errorStartMass == 1 && errorMassSta != 1 && errorMassSta != 2 && errorMassSta != 3 ? (
                    <font color="red" size="2">
                      {" "}
                      กรุณาเลือก ปี, เดือน, วัน{" "}
                    </font>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-12 col-lg-4">
                  <span>ถึงวันที่เปิดบิล</span>
                  <div className="row">
                    <div className="col-12 col-sm-4 px-md-1 mt-1">
                      <select
                        className={errorEnddate}
                        value={enddate1}
                        onChange={(e) => {
                          SetEnd1(e.target.value);
                        }}
                      >
                        <option value=""> -- ปี -- </option>
                        {years.map((year, index) => (
                          <option key={`year${index}`} value={year}>
                            {year + 543}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 col-sm-4  px-md-1 mt-1">
                      <select
                        className={errorEnddate}
                        value={enddate2}
                        onChange={(e) => {
                          SetEnd2(e.target.value);
                        }}
                      >
                        <option value=""> -- เดือน -- </option>
                        {monthTH.map((item, index) => (
                          <option value={item.id} key={item.id}>
                            {item.monthName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 col-sm-4  px-md-1 mt-1">
                      <select
                        className={errorEnddate}
                        value={enddate3}
                        onChange={(e) => {
                          SetEnd3(e.target.value);
                        }}
                      >
                        <option value=""> -- วัน -- </option>
                        {Array.from(Array(31), (e, i) => (
                          <option value={i + 1} key={i}>
                            {i + 1}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {errorEndMass == 1 && errorMassSta != 1 && errorMassSta != 2 && errorMassSta != 3 ? (
                    <font color="red" size="2">
                      {" "}
                      กรุณาเลือก ปี, เดือน, วัน{" "}
                    </font>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-12 col-lg px-md-1 mt-0" style={{ display: "none" }}>
                  <br />
                  <center style={{ marginTop: "10px;" }}>
                    <div className="form-check form-check-inline">
                      <input
                        checked={true}
                        style={{ width: "15px", height: "15px" }}
                        className="form-check-input"
                        type="checkbox"
                        name="inlineRadioOptions"
                        id="inlineRadio1"
                        value="1"
                        // onChange={(e) => {
                        //   if (cod !== "1" ? true : false) {
                        //     COD(e.target.value);
                        //   } else {
                        //     COD("");
                        //   }
                        // }}
                      />
                      <label className="form-check-label" htmlFor="inlineRadio1">
                        มี COD
                      </label>
                    </div>
                  </center>
                </div>
              </div>
              <br />
              <center>
                <i className="bi bi-search"></i>
                <button
                  disabled={loading == 1 ? true : false}
                  type="submit"
                  className={loading == 1 ? "btn btn-secondary" : "btn btn-primary"}
                >
                  <Icon.Search /> ค้นหา
                </button>
                <span>&nbsp;</span>
                <button type="button" className="btn btn-secondary" onClick={clear}>
                  <Icon.ArrowClockwise /> ล้างค่า
                </button>
              </center>
            </form>
          </div>
        </div>
      </div>
      <div>
      </div>

      {setData.length !== 0 || setErrorMassNoData === 1 ? (
        <div className="mt-4">
        <div className="d-flex justify-content-between mb-2">
        <div>
          <span>
                <button className="btn btn-info mr-2" onClick={()=>{
                  printJS({
                      printable: "printTable",
                      type: "html",
                    });
                }}><Icon.Printer /> พิมพ์</button>
          </span>
          <span>
          <button className="btn btn btn-info" onClick={ async (e)=>{
                  await handleSubmit2Excel();

                }}><Icon.FileEarmarkArrowUp /> export</button>
          </span>
        </div>
        <ExcelFile
              element={
                // <button style={{ display: "none" }} id="downloadEx">
                <button style={{display: "none"}} className="btn btn-primary" id="downloadEx1">
                  Download Data
                </button>
              }
              filename="COD History"
            >
              <ExcelSheet data={setData2} name={"COD History"}>
                <ExcelColumn label="เลขลาเบล" value="awb_code" />
                <ExcelColumn label="สำหรับลูกค้า" value="ref_no" />
                <ExcelColumn label="ว/ด/ป ที่โอนเงินคืนลูกค้า" value="created_date" width={{ wpx: 300 }}/>
                <ExcelColumn label="รหัสลูกค้า" value="cust_code" />
                <ExcelColumn label="ข้อมูลผู้ส่ง" value="sender_name" />
                <ExcelColumn label="หมายเลขโทรศัพท์" value="sender_phone" width={{ wpx: 80 }} /> 
                <ExcelColumn label="จำนวนเงินCOD" value="cod_cost" /> 
                <ExcelColumn label="ธนาคาร" value="bank_id" />
                <ExcelColumn label="หมายเลขบัญชี" value="bookbank_code" />
                <ExcelColumn label="ชื่อบัญชี" value="bookbank_name" />
                <ExcelColumn label="ข้อมูลผู้รับ" value="receiver_name" />
                <ExcelColumn label="เบอร์โทรผู้รับ" width={{ wpx: 160 }} value="receiver_phone" />
                <ExcelColumn label="ที่อยู่" value="receiver_address" />
                <ExcelColumn label="อำเภอ" value="receiver_amphur" />
                <ExcelColumn label="จังหวัด" value="receiver_province" />
                <ExcelColumn label="วันที่จัดส่งพัสดุฯสำเร็จ" value="receiver_date"/>
                <ExcelColumn label="ครบกำหนด" value="due_date" />  
                <ExcelColumn label="หมายเหตุ" value="remarkMg" />
                <ExcelColumn label="วันที่โอน" value="bank_transfer_date" />
                <ExcelColumn label="เลขที่โอน" value="bank_transfer_receipt" />
              </ExcelSheet>
            </ExcelFile>
            <div className = "col-1 mb-2">
              <select
                value={pageSizes}
                className="form-control"
                aria-label="Default select example"
                onChange={(e) => {
                  pageSize(e.target.value);
                }}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
        </div>
          {/* </div> */}


          <div style={{ overflow: "Auto" }} id="printTable">
            <table  className="table table-bordered">
              <thead style={{ backgroundColor: "#3f75c1" }}>
                <tr style={{ color: "#FFFFFF" }}>
                  <td>#</td>
                  <td>หมายเลขโทรศัพท์</td>
                  {/* <td>ว/ด/ป<br/>ที่โอนเงินคืนลูกค้า</td> */}
                  <td>วันที่พัสดุฯส่งถึงผู้รับ</td>
                  <td>รหัสลูกค้า</td>
                  <td>ข้อมูลผู้ส่ง</td>
                  <td>บาร์โค้ด/สำหรับลูกค้า</td>
                  <td>ข้อมูลผู้รับ</td>
                  <td>จำนวนเงินCOD</td>
                  <td>เลขที่โอน</td>
                  <td>หมายเหตุ</td>
                  { localStorage.getItem('role_type') == "1" || localStorage.getItem('role_type') == 1 ? (
                    <td>ผู้อัพโหลดหลักฐาน</td>
                    ) : ""
                  }
                </tr>
              </thead>
              <tbody>
                {setData.map((value, index) => (
                  <tr key={value.awb_code}>
                    <td>{(setPage.current_page - 1) * pageSizes + (index + 1)}</td>
                    <td>{value.sender_phone}</td>
                    <td>
                      {" "}
                      {value.receiver_date !== " " || value.receiver_date !== null ? (
                        <DateTH date={value.receiver_date.substring(0, 10)} />
                      ):(
                        "รอจัดส่ง"
                      )}
                      
                    </td>
                    <td>{value.cust_code != null ? value.cust_code : value.ref_no}</td>
                    <td>
                    <p>{value.bookbank_name}</p>
                    {bank.map((data) => (
                        data.bank_id === value.bank_id ? " "+data.bankName+" ":""
                    ))}
                      <p>{value.bookbank_code}</p>
                      
                    </td>
                    <td>
                      <p>
                      {value.ref_no === null || value.ref_no === '0' ? " " : value.ref_no}
                      </p>
                    <p>
                    {value.awb_code}
                    </p>
                    </td>
                    <td>
                      <p>{value.receiver_name}</p>
                      <p>{value.receiver_amphur}</p>
                      <p>{value.receiver_province}</p>
                    </td>
                    <td>{value.cod_cost}</td>
                    <td>
                      <DateTH date={value.bank_transfer_date} />
                      {value.bank_transfer_receipt}
                    </td>
                    {/* <td align="center" style={{ width: "170px" }}>
                    <center>
                          <img src={URLTEST+value.bank_transfer_image.substring(2)} width="100" height="100" alt="" />
                        </center>
                    </td> */}
                    <td>{value.remarkMg}</td>
                    { localStorage.getItem('role_type') == "1" || localStorage.getItem('role_type') == 1 ? (
                    <td>{value.emp_responsible}</td>
                    ) : ""
                    }
                    {setData.length == index + 1 ? checked() : ""}
                  </tr>
                ))}
              </tbody>
            </table>
            <center>
              {setErrorMassNoData == 1 ? (
                <font color="red" size="3">
                  {" "}
                  ไม่พบข้อมูล!{" "}
                </font>
              ) : (
                ""
              )}
            </center>
          </div>
          <div className="d-flex justify-content-between">
            <div>จำนวนทั้งหมด {setPage.total} รายการ</div>
            <div>
              <Pagination
                count={parseInt(setPage.last_page)}
                page={currentPage}
                onChange={handleChangePage}
                color="primary"
                size="small"
                defaultPage={6}
                siblingCount={1}
              />
            </div>
          </div>
          <br />
        </div>
      ) : loading === 1 ? (
        <div className="m-5">
          <div className="text-center">
            <div className="spinner-border  text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            {" ..."}
            กำลังโหลดข้อมูล
          </div>
        </div>
      ) : (
        ""
      )}

      <br />
      <br />
    </>
  );
}
